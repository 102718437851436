import styled from 'styled-components';

export const HomePromos = styled.div`
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  ::selection {
    color: #fff;
    background: #00a6e8;
  }
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    padding: 0 35px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const HomePromosItem = styled.div`
  display: block;
  position: relative;
  margin: 0;
  padding: 60px 110px 110px 110px;
  text-align: center;
  @media (max-width: 1799px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (max-width: 1199px) {
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 991px) {
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 767px) {
    padding: 35px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
`;

export const HomePromosItemImage = styled.img`
  width: auto;
  height: 107px;
  margin: 0 0 40px 0;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    height: 70px;
  }
`;
export const HomePromosItemHeader = styled.h2`
  margin: 0 0 20px 0;
  font-size: 40px;
  line-height: 45px;
  font-family: 'MrAlex', sans-serif;
  position: relative;
  color: #00539d;
  font-weight: bold;
  @media (max-width: 1399px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 991px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

export const HomePromosItemParagraph = styled.p`
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: 1799px) {
    font-size: 21px;
  }
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 26px;
  }
`;
export const HomePromosItemButton = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;
