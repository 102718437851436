import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DELAY } from '../../constants';
import HomePromos from './homePromos/homePromos';
import * as Styled from './homePromosListStyle';

const HomePromosList = ({ homePromosList = [] }) => {
  const delay = DELAY.homePromo;
  return (
    <Styled.HomePromosContainer className="px-0">
      <Row className="g-0">
        {homePromosList.map((homePromoData, i) => (
          <Col key={i} className="px-0" xs={12} md={4}>
            <HomePromos homePromoData={homePromoData} delay={delay * (i + 1)} />
          </Col>
        ))}
      </Row>
    </Styled.HomePromosContainer>
  );
};

export default HomePromosList;
